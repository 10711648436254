@import "./pallete";

a, .a {
  color: white;
  cursor: pointer;

  &:hover {
    color: map_get($mat-brand, 50)
  }

  &--underlined {
    cursor: pointer;
    text-decoration: underline;
  }
}
