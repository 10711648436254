@import "./pallete"

.select
  background-color: var(--mdc-theme-background)
  color: white
  border: 1px #3a3d4f solid
  padding: 6px 9px
  outline: none
  cursor: pointer
  font-size: 15px
  appearance: none
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
  background-repeat: no-repeat
  background-position: 97% 3px
  margin-bottom: 20px

  &--disabled
    pointer-events: none

  &--full
    width: 100%


.mdc-text-field--filled
  background: map_get($mat-brand, 800) !important
