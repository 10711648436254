@import "./devices";
@import "./pallete";

.container {
  padding: 0 24px;
  max-width: 1200px;
  margin: auto;

  @include phones-lg {
    padding: 0;
  }
}

.page {
  @extend .container;
  min-height: 100vh;
  max-height: 100vh;
  padding-top: 20px;
  background-color: $background-color;

  &--full {
    height: 100vh;
  }

  &--loading {
    pointer-events: none;
    opacity: 0.5;
  }

}

.page__inner {
  min-height: 100vh;
}
