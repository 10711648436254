@import "./pallete";
@import "./devices";

.advanced-table {
  border-collapse: separate;
  margin: auto;

  &__wrapper {
    overflow: auto;
    margin-bottom: 50px;

    &--full-size {
      max-height: 100%;
      overflow-y: visible;

      @include tablets-lg {
        overflow: auto;
      }
    }

    &--full-height {
      max-height: 100%;
      overflow-y: hidden;
    }

    &--full-width {
      width: 100%;
    }

    &--height-80vh {
      max-height: 80vh;
    }
  }

  &__td--sort {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: underline;
    line-height: 1.4;
    cursor: pointer;
  }

  &__tr--separated td {
    border-bottom: 1px solid white;
  }

  &__text {
    max-width: 400px;
    overflow: auto;
    max-height: 80px;
    min-width: 240px;
    white-space: break-spaces;
    display: block;
  }

  tr {
    background-color: map_get($mat-brand, 800);
    border-radius: 5px;
    overflow: hidden;

    &:not(.advanced-table__tr--no-accent):not(.advanced-table__tr--error):not(.advanced-table__tr--accent):nth-child(2n) {
      background-color: darken(map_get($mat-brand, 800), 1);
    }

    &.advanced-table__tr--accent {
      background-color: darken(map_get($mat-brand, 800), 4);
    }

    &.advanced-table__tr--pointer {
      cursor: pointer;
    }

    &.advanced-table__tr--disabled {
      background-color: darken(map_get($mat-brand, 400), 8);
    }

    &.advanced-table__tr--error {
      background-color: darken(map_get($mat-error, A200), 20);
    }

    &:hover {
      background: map_get($mat-brand, 700) !important;
    }

    &.advanced-table__tr--pointer:hover {
      cursor: pointer;
    }
  }

  thead {
    tr {
      font-size: 13px;

      @include phones {
        font-size: 12px;
      }
    }

    td,
    th {
      background-color: map_get($mat-brand, 700);
      padding: 6px 14px;
      font-weight: 400;
      position: sticky;
      top: 0;
      z-index: 1;

      @include phones {
        padding: 4px 8px;
      }
    }

    .advanced-table__td--fixed {
      z-index: 3 !important;
    }
  }

  tbody {
    td,
    th {
      font-weight: 400;
    }
  }

  td,
  th {
    vertical-align: middle;
    padding: 4px 14px;
    line-height: 1.4;
    letter-spacing: 0.3px;
    font-size: 14px;
    text-align: center;

    &.advanced-table__td--text {
      text-align: center;
    }

    &.advanced-table__td--nowrap {
      white-space: nowrap;
    }

    &.advanced-table__td--fixed {
      position: sticky;
      background-color: map_get($mat-brand, 700);
      left: 0;
      overflow-x: auto;
      z-index: 1;
      border-bottom: 1px solid #111217;
      padding-bottom: 2px;

      @include phones {
        max-width: 100px;
      }
    }

    &.advanced-table__td--right {
      text-align: right;
    }

    &.advanced-table__td--button {
      padding-top: 6px;
      padding-bottom: 6px;
    }

    &.advanced-table__td--no-padding {
      padding: 0;
    }

    &.advanced-table__td--no-x-padding {
      padding-left: 0;
      padding-right: 0;
      display: flex;
      align-content: center;
    }

    &.advanced-table__td--no-y-padding {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.advanced-table__td--one-line {
      display: flex;
    }

    @include phones {
      padding: 2px 8px;
      font-size: 12px;
    }
  }

  &__content--align-center {
    padding: 0 8px;
    display: flex;
    align-items: center;
  }

  &__content--limited {
    max-width: 300px;
    overflow: auto;
    max-height: 200px;
    white-space: normal;
    margin: auto;
    min-width: 130px;
  }

  &__content--one-line {
    display: flex;
  }

  &__content--sort {
    display: flex;
    align-content: center;
    text-decoration: underline;
    cursor: pointer;

    mat-icon {
      height: 16px;
    }
  }

  small {
    font-size: 12px;
  }

  &--clickable {
    tbody tr {
      cursor: pointer;

      &:hover {
        background: #2a2c36;
      }
    }
  }

  &--multiline {
    tbody td {
      white-space: normal;
      line-height: 1.4;
    }
  }

  &--text-left {
    td {
      text-align: left;
    }
  }

  &--no-margin {
    margin: 0;
  }

  &--center {
    margin: 0 auto;
  }

  &--left {
    margin-left: 0;
  }

  &--full-width {
    width: 100%;
  }

  &--loading {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
