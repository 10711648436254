@import "./pallete";

.input-base {
  appearance: none;
  outline: none;
  border: 1px solid map_get($mat-brand, 500);
  border-radius: 2px;
  background-color: $background-color2;
  color: white;
  position: relative;
  margin-bottom: 16px;
  box-sizing: border-box;
  padding: 7px 16px 5px 16px;
  transition: border-color 0.2s ease;

  &:hover {
    border-color: map_get($mat-brand, 100);
  }
  &:focus {
    border-color: map_get($mat-brand, 200);
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
  }  /* <-- Apparently some margin are still there even though it's hidden */


  &[type=number] {
    -moz-appearance: textfield;
  }  /* Firefox */


  &__error {
    font-size: 12px;
    position: relative;
    //margin-bottom: 16px;
    top: -8px;
    color: map_get($mat-error, A100);
  }

  &--error {
    border-color: map_get($mat-error, A100);
  }
}
.input {
  @extend .input-base;
  line-height: 1.8;
  font-size: 14px;

  &__error {
    @extend .input-base__error;
  }
  &--100 {
    max-width: 100px;
  }
  &--full {
    width: 100%;
  }
  &--sm {
    font-size: 12px;
    line-height: 1.4;
  }
  &__label {
    margin-bottom: 4px;
    font-size: 14px;
    color: white;
  }
  &__hint {
    font-size: 11px;
    margin-top: -8px;
  }
}

.fancy-input {
  display: block;
  flex-grow: 1;
  outline: none;
  color: white;
  font-size: 20px;
  appearance: none;
  border: 0;
  background: transparent;
  padding: 10px 20px;

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-height: 50px;
    border-radius: 0;
    transition: 0.2s cubic-bezier(0.25, 0.1, 0.8, 1.2);
    opacity: 0.9;

    &--active,
    &:hover,
    &:focus,
    &:active {
      opacity: 1;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 14rem;
    line-height: 1;
    vertical-align: middle;
    transition: color .5s;
    cursor: pointer;
    appearance: none;
    border: 0;
    background: transparent;
    padding: 0 16px;
    outline: none;
  }

  &__icon {
    height: 20px;
  }

  &--textarea {
    flex-grow: 1;
    color: white;
    outline: none;
    border: 0;
    font-size: 16px;
    resize: none;
    appearance: none;
  }
}


