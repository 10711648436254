* {
  font-family: "Nunito Sans", sans-serif;
}

.h1, .h2, .h3, .h4, .h5 {
    text-align: center;
    width: 100%;
    color: #fff;
}

.h1 {
  font-size: 36px;
  margin-bottom: 26px;
}

.h2 {
  font-size: 28px;
  margin-bottom: 16px;
}

.h3 {
  font-size: 26px;
  margin-bottom: 22px;
}

.h4 {
  font-size: 22px;
  margin-bottom: 18px;
}
