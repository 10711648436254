@import "./assets/styles/reset";
@import "./assets/styles/page";
@import "./assets/styles/devices";
@import "./assets/styles/buttons";
@import "./assets/styles/typography";
@import "./assets/styles/inputs";
@import "./assets/styles/select";
@import "./assets/styles/textarea";
@import "./assets/styles/checkbox";
@import "./assets/styles/advanced-table";
@import "./assets/styles/links";
@import "./assets/styles/scrollbar";

* {
  box-sizing: border-box;
  --mat-sidenav-container-divider-color: #111520;
  --mat-sidenav-content-background-color: #111520;
  --mat-sidenav-content-text-color: white;
}

body {
  background-color: $background-color;
  color: white;
  line-height: 1.4;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center top;
  background-attachment: fixed;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
